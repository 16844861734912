import firebase from "gatsby-plugin-firebase";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DateTimePicker from 'react-datetime-picker'
import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, AppBar, Button, Card, Dialog, Switch } from "@material-ui/core";
import { Link } from "gatsby";
import HomeAppBar from "../components/HomeAppBar";
import axios from "axios";
import League from "../classes/League";
 

export default class EditDivision extends React.Component {

    defaultnewMatchInfo = {
        homeTeam: "",
        visitorTeam: "",
        matchDate: new Date(),
        startTimeAMPM: "AM",
        startTimeMinute: "00",
        startTimeHour: "9",
        matchName: "My Test Stream",
        //matchDescription:"Jack's first test of the stream",
        streamKey: "k8xm-thmt-1usc-e1km-6a3b",
        streamURL: "rtmp://x.rtmp.youtube.com/live2/",
        publicStreamURL: "",
    }

    state = {
        showAddTeam: false,
        showAddMatch: false,
        showAddPlayer: false,
        newplayerInfo: {},
        newteamInfo: {},
        newMatchInfo: this.defaultnewMatchInfo,
        teams: [],
        matches: [],
        isActive: false,
        matchEdits: {},
        streamkeys: {},
        defaultHomeTeam: "",
        defaultVisitorTeam: "",
        startDate: new Date()



    }



    componentDidMount() {
        const urlSearchParams = new URL(window.location);
        var matchid = urlSearchParams.searchParams.get("matchid")
        firebase.database().ref("streamkeys").once("value", (streamkeys) => {
            this.setState({ streamkeys: streamkeys.val() })
        })
        firebase.database().ref("matches").child(matchid).on("value", (data) => {
            this.setState({ ...data.val(), matchid: matchid,startDate: new Date(data.val()["startDate"])  })
            console.log(data.val())
            console.log(data.val()["startDate"])
            //this.setState({ })
            firebase.database().ref("seasons").child(data.val().seasonid).child("divisions").child(data.val().divisionid).child("teams").once("value", (teams) => {
                this.setState({ teams: teams.val(), })
                //console.log(this.getTeamKey(this.state.TeamAName,teams.val()), this.getTeamKey(this.state.TeamBName, teams.val()))
            })
        })

        //var divisionid = urlSearchParams.searchParams.get("divisionid")
        // firebase.database().ref("seasons").child(tournamentId).child("divisions").child(divisionid).on("value", (data) => {
        //     this.setState({ ...data.val(), seasonid: tournamentId, divisionid:divisionid })
        //     console.log(data.val())
        //     console.log(divisionid)
        //     firebase.database().ref("matches").orderByChild("divisionid").equalTo(divisionid).once("value",(data1)=>{
        //         console.log(data1.val())
        //         this.setState({matches: data1.val()})
        //     })
        // })

    }
    styles = {
        table: {
            maxWidth: 500,
            margin: 5
        }
    }

    TeamOptions = (props) => {
        return Object.keys(this.state.teams).map((homeTeamId) => {

            console.log(this.state.teams[homeTeamId]["name"] == this.state.TeamAName)
            if (props.isTeamA == true) {
                return (
                    <option selected={this.state.teams[homeTeamId] == this.state.TeamAName} value={homeTeamId}>{this.state.teams[homeTeamId]["name"]}</option>
                )
            }
            else {
                return (
                    <option selected={this.state.teams[homeTeamId]["name"] == this.state.TeamBName} value={homeTeamId}>{this.state.teams[homeTeamId]["name"]}</option>
                )
            }

        })
    }

    isSelectedTeamKey = (teamkey, isTeamA) => {

    }

    getTeamKey = (TeamName, teamList) => {
        for (var teamkey = 0; teamkey < teamList.length; teamkey++) {
            console.log(teamkey)
            console.log(teamList[teamkey])
            console.log(TeamName)
            if (teamList[teamkey]["name"] == TeamName) {
                console.log("found a match")
                return teamkey
            }
        }
    }

    render() {
        console.log(this.state)
        console.log(this.state.startDate)
        console.log(new Date(this.state.startDate))
        return (
            <div>
                <HomeAppBar title={this.state.seasonName}></HomeAppBar>
                <Card style={this.styles.table}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Edit Match</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>Home Team</TableCell>
                                <TableCell>
                                    <select onChange={(ev) => {
                                        this.setState({ matchEdits: { ...this.state.matchEdits, TeamAName: this.state.teams[ev.target.value]["name"] } })
                                    }}>


                                        <this.TeamOptions isTeamA={true}></this.TeamOptions>
                                    </select>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Visiting Team</TableCell>
                                <TableCell>
                                    <select onChange={(ev) => {
                                        this.setState({ matchEdits: { ...this.state.matchEdits, TeamBName: this.state.teams[ev.target.value]["name"] } })
                                    }}>

                                        <this.TeamOptions isTeamA={false}></this.TeamOptions>
                                    </select>
                                </TableCell>
                            </TableRow>


                            <TableRow>
                                <TableCell>Youtube Event URL</TableCell>
                                <TableCell><input defaultValue={this.state.publicStreamURL} onChange={(ev) => {

                                    this.setState({ matchEdits: { ...this.state.matchEdits, publicStreamURL: ev.target.value } }, () => { console.log(this.state) })
                                }} type="text"></input></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>{"Date & Time"}</TableCell>
                                <TableCell><DateTimePicker
                                    calendarIcon={null}
                                    disableClock={true}
                                    disableCalendar={true}
                                    value={typeof this.state.startDate == "undefined" || this.state.startDate == "Invalid Date" ?  new Date().toString() : this.state.startDate}
                                    onChange={(date) => {
                                        var newStart = new Date(date)
                                        var anewStart = new Date(date)
                                        this.setState({ matchEdits: { ...this.state.matchEdits, startDate: newStart}, startDate: anewStart }, () => { console.log(this.state) })
                                    }}
                                /></TableCell>
                            </TableRow>

                            <TableRow>

                                <TableCell><Button

                                    onClick={() => {
                                        console.log(this.state.matchEdits)
                                        firebase.database().ref("matches").child(this.state.matchid).update({ ...this.state.matchEdits })
                                    }} >Save</Button></TableCell>
                                <TableCell><Button onClick={() => {
                                    this.setState({ showAddMatch: this.state.showAddMatch ? false : true })
                                }}>Close</Button></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Card>
            </div>
        )
    }
}